
let url = window.location.origin
if(url.includes('localhost')){
  console.log('setando url local ')
  url = 'http://localhost:4021'
  // url = 'https://apitajhotel.cd.economysoftware.com.br'
}else{
    console.log('setando url remota')
    let arrayUrl = url.split('//')
    url = `https://api${arrayUrl[1]}`
}


// url = 'https://apiequipotel.cd.economysoftware.com.br'

export var BUILD_TIPO_INTEGRACAO = 'HITS'
export var BUILD_API_URL = url
export var BUILD_ID_HOTEL = 1
export var BUILD_APIES_URL = 'https://apies.economysoftware.com.br'
export var BUILD_TOKEN_APIES ='5e47648febb18324243329f0bd0ef5e871ecd41f'
export var BUILD_ENVIAR_PAGAMENTO_CHECKOUT_AO_PMS ='N'
export var BUILD_ID_CLIENTE = 49
